.stickyFirstCell_light {
  position: absolute;
  left: 0;
  top: auto;
  margin: 0;
  white-space: nowrap;
  background-color: white;
  border-right: 0.0625rem solid #f0f2f5;
}

.stickyFirstCell_dark {
  position: absolute;
  left: 0;
  top: auto;
  margin: 0;
  white-space: nowrap;
  background-color: #202940;
  border-right: 0.0625rem solid #f0f2f566;
}
