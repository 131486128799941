.clearFilter {
	transition: opacity 0.15s ease-in-out !important;
	opacity: 0;
}

.datePickerContainer:hover .clearFilter {
	opacity: 1;
}

.fullWidthDatePicker {
	width: 100% !important;
}

.clearFilter_light {
	position: absolute !important;
	top: 50% !important;
	right: 5px !important;
	transform: translateY(-50%) !important;
	color: #344767 !important;
}

.clearFilter_dark {
	position: absolute !important;
	top: 50% !important;
	right: 5px !important;
	transform: translateY(-50%) !important;
	color: rgba(255, 255, 255, 0.524) !important;
}

.datePickerContainer {
	position: relative;
}
