.comparison-data-table-reference-row {
	background-color: #1a73e8 !important;
}

.comparison-data-table-reference-row * {
	color: white !important;
	font-weight: bold !important;
}

.cell-comparison-table {
	color: inherit;
}

.cell-comparison-table * {
	color: inherit !important;
	font-weight: bold;
}
