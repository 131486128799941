.dialogInput {
	width: 100%;
}

.colorContainer {
	display: flex;
	flex-direction: row;
}

.colorPreset {
	flex: 8 !important;
}

.buttonColorPicker {
	margin-left: 0.5vw;
	flex: 1;
	/* width: 5vh; */
	/* height: 100%; */
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 4px solid #00000052;
}

.buttonColorPickerIcon {
	color: #eeeeee;
}
