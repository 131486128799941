:root {
    --star-size: 30px;
}

.stars {
    display: flex;
    align-items: center;
    margin: 5px;
}

.stars-container {
    position: relative;
    height: var(--star-size);
    width: var(--star-size);
}

.stars-row-container {
    position: absolute; 
    height: var(--star-size); 
    width: var(--star-size); 
    overflow: hidden;
}

.star-base {
    position: absolute;
    width: 100% !important; 
    height: 100% !important;
}

.writtedNote {
    margin-left: 5px;
}