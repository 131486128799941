.mandatoryField {
	color: red;
	font-size: 0.8rem;
	font-weight: 400;
	margin-left: 0.5rem;
}

li {
	min-width: 0 !important;
}

.customBoxInputContainer {
	border-color: #d2d6da;
	border-radius: 0.375rem;
	border-width: 1px;
	border-style: solid;
	padding: 0.25rem 0.75rem;
	color: #252525;
	font-family: Roboto;
	font-size: 0.875rem;
}

.customSwitchContainer {
	border-color: #d2d6da;
	border-radius: 0.375rem;
	border-width: 1px;
	border-style: solid;
	padding: 0.25rem 0.75rem;
	color: #252525;
	font-family: Roboto;
	font-size: 0.875rem;
	cursor: pointer;
}

.displayLeftTab {
	overflow: auto;
	/* max-height: 60vh; */
}

.displayRightTab {
	overflow-y: scroll;
	max-height: 60vh;
}

.colorPickerContainer {
	width: 3rem;
	height: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white !important;
	cursor: pointer;
}

.previewColorPickerContainer {
	width: 1.5rem;
	height: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white !important;
	cursor: pointer;
}

.rangeContainerSolo {
	margin-left: 5px;
	margin-right: 5px;
	display: flex;
	flex-direction: column;
}

.rangeContainer {
	margin-left: 5px;
	margin-right: 5px;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.rangeContainerBar {
	height: 15px;
	border-radius: 3px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	cursor: pointer;
	/* background-color: #8f93a9 !important; */
}

.rangeContainerBar:hover {
	filter: brightness(90%);
}

.rangeContainerRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.rangeContainerItemLeft {
	margin-right: 10px;
}

.rangeContainerItemRight {
	margin-left: 10px;
}

.rangeContainerItem {
	transform: translateY(110%);
}

.rangeContainerItem:hover {
	color: red !important;
	cursor: pointer;
}

.rangeContainerItemCannotDelete:hover {
	color: initial !important;
	cursor: initial !important;
}
